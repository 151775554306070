import * as React from 'react'
import { useForm } from 'react-hook-form'
import { GIOrganLabel } from '../../../../types'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { applyOrganLabelAPI } from '../../../../reducers/func/dataManagementFunc'
import { fi } from 'date-fns/locale'

interface IEditOrganLabel {
    organlabel: GIOrganLabel
    onEdit: (organlabel: GIOrganLabel) => void
}

export const EditOrganLabel: React.FC<IEditOrganLabel> = (props: IEditOrganLabel) => {
    const [edited, setEdited] = React.useState<boolean>(false)
    const [firstOrganLabel, setFirstOrganLabel] = React.useState<number>(-1)
    const { organlabel, onEdit } = props
    const form = useForm()

    const handleOrganLabelChange = (event: SelectChangeEvent) => {
        const label = parseInt(event.target.value)
        // setSelLabel(label)
        setEdited(true)
        const neworganlabel: GIOrganLabel = {
            ...organlabel,
            label: label,
            edited: true
        }
        onEdit(neworganlabel)
    }

    const handleSetFirstLabel = (event: SelectChangeEvent) => {
        const label = parseInt(event.target.value)
        // setSelLabel(label)
        setEdited(true)
        var first_esophagus = false
        var first_stomach = false
        var first_smallbowel = false
        var first_colon = false
        if (label === 0) {
            first_esophagus = true
        }
        else if (label === 1) {
            first_stomach = true
        }
        else if (label === 2) {
            first_smallbowel = true
        }
        else if (label === 3) {
            first_colon = true
        }
        const neworganlabel: GIOrganLabel = {
            ...organlabel,
            first_esophagus: first_esophagus,
            first_stomach: first_stomach,
            first_smallbowel: first_smallbowel,
            first_colon: first_colon,
            edited: true
        }
        onEdit(neworganlabel)
    }

    const handleApplyChange = () => {
        var first_organ = "No"
        if (firstOrganLabel === 0) {
            first_organ = "esophagus"
        }
        else if (firstOrganLabel === 1) {
            first_organ = "stomach"
        }
        else if (firstOrganLabel === 2) {
            first_organ = "smallbowel"
        }
        else if (firstOrganLabel === 3) {
            first_organ = "colon"
        }

        applyOrganLabelAPI(organlabel.id as string, organlabel.label, first_organ)
            .then((response) => {
                setEdited(false)
                console.log("Apply change success: ", response)
                onEdit({ ...organlabel, edited: false })
            })
            .catch((error) => {
                console.log("Error applying change: ", error)
            })
    }

    React.useEffect(() => {
        if (organlabel !== null && organlabel !== undefined) {
            if (organlabel.first_esophagus) {
                setFirstOrganLabel(0)
            }
            else if (organlabel.first_stomach) {
                setFirstOrganLabel(1)
            }
            else if (organlabel.first_smallbowel) {
                setFirstOrganLabel(2)
            }
            else if (organlabel.first_colon) {
                setFirstOrganLabel(3)
            }
            else {
                setFirstOrganLabel(-1)
            }
            if (organlabel.edited !== undefined && organlabel.edited !== null) {
                setEdited(organlabel.edited)
            } else {
                setEdited(false)
            }

        }
    }, [organlabel])

    return (
        <form>
            <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                <Grid item xs={6}>
                    <Grid container direction='column' spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <Box>
                                    <Typography variant='body2'>Id: {(organlabel !== undefined && organlabel !== null) ? organlabel.id : ''} </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <FormControl variant="standard">
                                    <InputLabel id="organlabel-select-standard-label">Organ Label</InputLabel>
                                    <Select
                                        labelId="organlabel-select-standard-label"
                                        id="organlabel-select-standard"
                                        value={(organlabel !== undefined ? organlabel.label : -1) as unknown as string}
                                        label="organlabel"
                                        color="primary"
                                        sx={{ width: "107px" }}
                                        onChange={handleOrganLabelChange}
                                    >
                                        <MenuItem value={-1}>N/A</MenuItem>
                                        <MenuItem value={0}>Esophagus</MenuItem>
                                        <MenuItem value={1}>Stomach</MenuItem>
                                        <MenuItem value={2}>Small Bowel</MenuItem>
                                        <MenuItem value={3}>Colon</MenuItem>
                                    </Select>
                                </FormControl>

                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                                <FormControl variant="standard">
                                    <InputLabel id="organfirstlabel-select-standard-label">Is First Label</InputLabel>
                                    <Select
                                        labelId="organfirstlabel-select-standard-label"
                                        id="organfirstlabel-select-standard"
                                        value={firstOrganLabel as unknown as string}
                                        label="organfirstlabel"
                                        color="primary"
                                        sx={{ width: "107px" }}
                                        onChange={handleSetFirstLabel}
                                    >
                                        <MenuItem value={-1}>No</MenuItem>
                                        <MenuItem value={0}>Esophagus</MenuItem>
                                        <MenuItem value={1}>Stomach</MenuItem>
                                        <MenuItem value={2}>Small Bowel</MenuItem>
                                        <MenuItem value={3}>Colon</MenuItem>
                                    </Select>
                                </FormControl>

                            </Stack>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Stack direction='column' spacing={1} sx={{ m: 1 }} alignItems='flex-start' justifyContent='flex-start'>
                        <Button
                            variant='contained'
                            disabled={!edited}
                            onClick={handleApplyChange}
                        >
                            Apply change
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    )
}