import { Avatar, Box, Button, ButtonGroup, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Input, InputLabel, Menu, MenuItem, MenuProps, Radio, RadioGroup, Select, SelectChangeEvent, Slider, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import { styled, alpha } from '@mui/material/styles';
import { GIOrganLabelViewset, ImageDataframe, ImageDataframeViewset, Lesion, LesionLabel, LesionLabelViewset, instanceofImageDataframe, instanceofLesionLabel } from '../../../../types'
import { ImageDataframeList } from '../lists/ImageDataframeList'
// import { Image, Layer, Stage } from 'react-konva'
// import { CustomFab } from '../../controls/CustomControls'
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClearIcon from '@mui/icons-material/Clear';
// import { useForm } from 'react-hook-form'
import { LesionLabelImageView } from './LesionLabelImageView'
import { EditLesionLabel } from '../forms/EditLesionLabel'
import { Heatmap } from '../../charts/Heatmap'
import { deleteLesionLabelsAPI, fetchLesionLabelAPI, marklesionforexportAPI, marklesionlabelsAPI, unmarklesionforexportAPI } from '../../../../reducers/func/dataManagementFunc';
import { set } from 'lodash';


interface ILesionLabelView {
    lesion: Lesion | null
}

const DropDownMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
export const LesionLabelView: React.FC<ILesionLabelView> = (props: ILesionLabelView) => {
    const [viewlabel, setViewLabel] = React.useState<string>('')
    const [viewfirstlabel, setViewFirstLabel] = React.useState<string>('')
    const [multiSelection, setMultiSelection] = React.useState<boolean>(false)
    const [lesionlabelviewset, setlesionLabelViewset] = React.useState<LesionLabelViewset | null>(null)
    const [viewoption, setViewOption] = React.useState<string | null>('thumb')
    const [ddanchorEl, setDDAnchorEl] = React.useState<null | HTMLElement>(null);
    const [loading, setLoading] = React.useState<boolean>(false)
    const open = Boolean(ddanchorEl);
    const rows = 20
    const cols = 20
    const handleDDMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setDDAnchorEl(event.currentTarget);
    }
    const handleDDMenuClose = () => {
        setDDAnchorEl(null);
    }
    const { lesion } = props

    const handleViewOptionChange = (event: React.MouseEvent<HTMLElement>,
        newOption: string | null) => {
        setlesionLabelViewset(null)
        setViewOption(newOption)
    }

    const handelViewLabelChange = (event: SelectChangeEvent) => {
        setViewLabel(event.target.value as string)
        setlesionLabelViewset(null)
    }

    const handelViewFirstLabelChange = (label: string) => {
        setViewFirstLabel(label)
        handleFetchData(1)
    }

    const handleFetchData = (page: number) => {
        if (lesion) {
            setLoading(true)
            const num_items = viewoption === 'thumb' ? 12 : rows * cols
            fetchLesionLabelAPI(lesion.id, viewlabel, viewfirstlabel, page, num_items)
                .then((response) => {
                    if (page > 1 && lesionlabelviewset !== null) {
                        setlesionLabelViewset({
                            ...lesionlabelviewset,
                            data: lesionlabelviewset?.data?.concat(response.data.results),
                            page: page,
                            count: response.data.count,
                        })
                    } else {

                        setlesionLabelViewset({
                            ...lesionlabelviewset,
                            data: response.data.results,
                            totalpages: response.data.total_pages,
                            page: 1,
                            count: response.data.count,
                            // selframe: response.data.results[0].dataframe,
                            selitem: response.data.results[0],
                            selitems: [],
                            pointer: 0
                        })
                    }
                    setLoading(false)

                })
                .catch((error) => {
                    console.log("Error fetching image data: ", error)
                    setLoading(false)
                })
        }
    }

    const handleLoadMore = () => {
        if (lesionlabelviewset !== null) {
            if (lesionlabelviewset.page as number + 1 <= (lesionlabelviewset.totalpages as number)) {
                handleFetchData(lesionlabelviewset.page as number + 1)
            }
        }
    }

    // const handleRefreshData = () => {
    //     if (lesion) {
    //         fetchLesionLabelAPI(lesion.id, viewlabel, 1)
    //             .then((response) => {

    //                 setlesionLabelViewset({
    //                     ...lesionlabelviewset,
    //                     data: response.data.results,
    //                     totalpages: response.data.total_pages,
    //                     count: response.data.count,
    //                 })
    //             })
    //             .catch((error) => {
    //                 console.log("Error refreshing data: ", error)
    //             })
    //     }
    // }

    // const handleDataListScrollEnd = (event: any) => {
    //     if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop == 420) {
    //         if (lesionlabelviewset?.page as number + 1 <= (lesionlabelviewset?.totalpages as number)) {
    //             handleFetchData(lesionlabelviewset?.page as number + 1)
    //         }
    //     }
    // }

    // const handleMarkUpdated = () => {

    //     handleRefreshData()
    // }

    // const handleFrameLabelEdited = (frame: ImageDataframe, label: number) => {
    //     // find label item
    //     const labelitem = lesionlabelviewset?.data?.find((element: LesionLabel) => element.dataframe?.id === frame.id)
    //     if (labelitem !== undefined && instanceofLesionLabel(labelitem)) {
    //         editLesionLabelAPI(labelitem.id as string, label)
    //             .then((response) => {
    //                 handleRefreshData()
    //             })
    //             .catch((error) => {
    //                 console.log("Error updating label: ", error)
    //             })
    //     }
    // }

    const handleFrameSelected = (item: ImageDataframe) => {
        // check type of item
        if (item !== undefined && instanceofImageDataframe(item)) {
            // let frameimage = new window.Image()
            // frameimage.src = item.imagedata || '' // Add a default value of an empty string if item.imagedata is undefined
            // frameimage.crossOrigin = 'anonymous'
            // frameimage.onload = () => {
            //     setSelFrameImage(frameimage)
            //     setZoomLevel(1)
            //     const x = (canvaswidth - frameimage.width) / 2
            //     const y = (canvasheight - frameimage.height) / 2
            //     setImageStartPos([x, y])
            // }
            // find pointer location
            if (lesionlabelviewset !== null) {
                const pointer = lesionlabelviewset.data?.findIndex((element: LesionLabel) => element.dataframe?.id === item.id)
                const selitems = lesionlabelviewset.selitems
                if (pointer !== undefined && pointer >= 0) {
                    if (multiSelection) {
                        if (lesionlabelviewset.selitems !== null) {
                            const index = lesionlabelviewset.selitems?.findIndex((element: LesionLabel) => element.dataframe?.id === item.id)
                            if (index === -1) {
                                // get item at gi_index
                                const item: any = lesionlabelviewset.data?.find((element: LesionLabel) => element.dataframe?.id === item.id)
                                selitems?.push(item as LesionLabel)
                            } else if (index !== undefined) {
                                selitems?.splice(index, 1)
                            }

                        }
                    }

                    setlesionLabelViewset({
                        ...lesionlabelviewset,
                        // selframe: item.dataframe,
                        selitem: lesionlabelviewset?.data?.[pointer] as LesionLabel,
                        pointer: pointer
                    })
                }
            }

        }
    }

    const nextFrame = () => {
        const pointer = lesionlabelviewset?.pointer as number
        if (pointer + 1 < (lesionlabelviewset?.data?.length as number)) {
            const nextframe = lesionlabelviewset?.data?.[pointer + 1]
            handleFrameSelected(nextframe?.dataframe as ImageDataframe)
        }
    }

    const prevFrame = () => {
        const pointer = lesionlabelviewset?.pointer as number
        if (pointer - 1 >= 0) {
            const prevframe = lesionlabelviewset?.data?.[pointer - 1]
            handleFrameSelected(prevframe?.dataframe as ImageDataframe)
        }
    }

    const handleLesionLabelEdited = (lesionlabel: LesionLabel) => {
        if (lesionlabelviewset !== null) {
            const newdata = lesionlabelviewset.data?.map((element: LesionLabel) => {
                if (element.id === lesionlabel.id) {
                    return lesionlabel
                } else {
                    return element
                }
            })
            setlesionLabelViewset({
                ...lesionlabelviewset,
                data: newdata,
                selitem: lesionlabel
            })
        }
    }

    const handleViewsetChanged = (viewset: ImageDataframeViewset | LesionLabelViewset | GIOrganLabelViewset) => {
        console.log("Viewset changed: ", lesionlabelviewset)
        setlesionLabelViewset(viewset as LesionLabelViewset)
    }

    const toggleMultiSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMultiSelection(event.target.checked);
        // Add current item to selected items
        if (lesionlabelviewset !== null) {
            if (event.target.checked) {
                const selitems: LesionLabel[] = []
                selitems.push(lesionlabelviewset.selitem as LesionLabel)
                setlesionLabelViewset({
                    ...lesionlabelviewset,
                    selitems: selitems
                })
            } else {
                setlesionLabelViewset({
                    ...lesionlabelviewset,
                    selitems: []
                })
            }
        }
    }

    const markSelectionsForExport = () => {
        if (lesionlabelviewset !== null) {
            const selitems = lesionlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize export list
                const exportlist: number[] = []
                selitems.forEach((element: LesionLabel) => {
                    // add item id to export list
                    exportlist.push(element.id as unknown as number)
                })
                // call mark for export API
                marklesionforexportAPI(lesion?.id as string, exportlist)
                    .then((response) => {
                        // refresh data
                        // setlesionLabelViewset(null)
                        handleFetchData(lesionlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error marking for export: ", error)
                    })
            }
        }
    }

    const unmarkSelectionsForExport = () => {
        if (lesionlabelviewset !== null) {
            const selitems = lesionlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize export list
                const exportlist: number[] = []
                selitems.forEach((element: LesionLabel) => {
                    // add item id to export list
                    exportlist.push(element.id as unknown as number)
                })
                // call mark for export API
                unmarklesionforexportAPI(lesion?.id as string, exportlist)
                    .then((response) => {
                        // refresh data
                        // setlesionLabelViewset(null)
                        handleFetchData(lesionlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error marking for export: ", error)
                    })
            }
        }
    }

    const markLesionLabels = (label: number) => {
        if (lesionlabelviewset !== null) {
            const selitems = lesionlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize labels list
                const labelslist: number[] = []
                selitems.forEach((element: LesionLabel) => {
                    // add item id to labels list
                    labelslist.push(element.id as unknown as number)
                })
                // call mark for export API
                marklesionlabelsAPI(lesion?.id as string, labelslist, label)
                    .then((response) => {
                        // refresh data
                        // setlesionLabelViewset(null)
                        handleFetchData(lesionlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error marking labels: ", error)
                    })
            }
        }
    }

    const deleteSelectedItems = () => {
        if (lesionlabelviewset !== null) {
            const selitems = lesionlabelviewset.selitems
            if (selitems !== null && selitems !== undefined) {
                // initialize labels list
                const labelslist: number[] = []
                selitems.forEach((element: LesionLabel) => {
                    // add item id to labels list
                    labelslist.push(element.id as unknown as number)
                }
                )
                deleteLesionLabelsAPI(labelslist)
                    .then((response) => {
                        // refresh data
                        handleFetchData(lesionlabelviewset.page as number)
                    })
                    .catch((error) => {
                        console.log("Error deleting labels: ", error)
                    })

            }
        }
    }



    // const zoomIn = () => {
    //     setZoomLevel(zoomLevel + 0.1)
    //     const new_x = canvaswidth / 2 - (canvaswidth / 2 - imageStartPos[0]) * 1.1
    //     const new_y = canvasheight / 2 - (canvasheight / 2 - imageStartPos[1]) * 1.1
    //     setImageStartPos([new_x, new_y])
    // }

    // const zoomOut = () => {
    //     setZoomLevel(zoomLevel - 0.1)
    //     const new_x = canvaswidth / 2 - (canvaswidth / 2 - imageStartPos[0]) / 1.1
    //     const new_y = canvasheight / 2 - (canvasheight / 2 - imageStartPos[1]) / 1.1
    //     setImageStartPos([new_x, new_y])
    // }

    // const dragStart = () => {
    //     setIsDragging(true)
    // }

    // const dragEnd = (event: any) => {
    //     setImageStartPos([event.target.x(), event.target.y()])
    //     setIsDragging(false)
    // }

    // const resetImageView = () => {
    //     setZoomLevel(1)
    //     const x = (canvaswidth - selFrameImage.width) / 2
    //     const y = (canvasheight - selFrameImage.height) / 2
    //     setImageStartPos([x, y])
    // }

    React.useEffect(() => {
        handleFetchData(1)
    }, [viewlabel])

    React.useEffect(() => {
        if (lesionlabelviewset == null) {
            handleFetchData(1)
        }
    }, [lesionlabelviewset])


    return (
        <>
            <Stack direction='row' spacing={0}>
                <Box
                    sx={{
                        width: '50%',
                        height: 500,
                    }}
                >
                    <Box
                        sx={{
                            width: 500,

                        }}>
                        <Grid container direction='row' spacing={1}>
                            <Grid item xs={4}>
                                <FormControl
                                    variant='standard'
                                    sx={{ minWidth: 80, pb: 1 }}>
                                    <InputLabel id="viewlabel-select-label">Label</InputLabel>
                                    <Select
                                        labelId="viewlabel-select-label"
                                        id="viewlabel-select"
                                        value={viewlabel}
                                        label="Label"
                                        onChange={handelViewLabelChange}>
                                        <MenuItem value={''}>All</MenuItem>
                                        <MenuItem value={0}>Normal</MenuItem>
                                        <MenuItem value={1}>Lesion</MenuItem>
                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item xs={6} alignContent='center' alignItems='center'>
                                <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                                    <IconButton
                                        disabled={lesion?.gi_organ?.gioc_giorganmarks === null || lesion?.gi_organ?.gioc_giorganmarks === undefined}
                                        onClick={() => { handelViewFirstLabelChange('esophagus') }}>
                                        <Tooltip title='Esophagus'>
                                            <Avatar color='primary' sx={{ width: 24, height: 24, fontSize: 11 }}>ES</Avatar>
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        disabled={lesion?.gi_organ?.gioc_giorganmarks === null || lesion?.gi_organ?.gioc_giorganmarks === undefined}
                                        onClick={() => { handelViewFirstLabelChange('stomach') }}>
                                        <Tooltip title='Stomach'>
                                            <Avatar color='primary' sx={{ width: 24, height: 24, fontSize: 11 }}>ST</Avatar>
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        disabled={lesion?.gi_organ?.gioc_giorganmarks === null || lesion?.gi_organ?.gioc_giorganmarks === undefined}
                                        onClick={() => { handelViewFirstLabelChange('smallbowel') }}>
                                        <Tooltip title='Small Bowel'>
                                            <Avatar color='primary' sx={{ width: 24, height: 24, fontSize: 11 }}>SB</Avatar>
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        disabled={lesion?.gi_organ?.gioc_giorganmarks === null || lesion?.gi_organ?.gioc_giorganmarks === undefined}
                                        onClick={() => { handelViewFirstLabelChange('colon') }}>
                                        <Tooltip title='Colon'>
                                            <Avatar color='primary' sx={{ width: 24, height: 24, fontSize: 11 }}>CO</Avatar>
                                        </Tooltip>
                                    </IconButton>
                                </Stack>
                            </Grid>
                            <Grid item xs={2} alignContent='center' alignItems='flex-end' textAlign='end'>
                                <ToggleButtonGroup
                                    value={viewoption}
                                    exclusive
                                    onChange={handleViewOptionChange}
                                    size='small'
                                    color='primary'

                                >
                                    <ToggleButton value='thumb' aria-label='thumb'>
                                        <ViewModuleIcon />
                                    </ToggleButton>
                                    <ToggleButton value='heatmap' aria-label='heatmap'>
                                        <ViewCompactIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                        {viewoption === 'thumb' ? (

                            <ImageDataframeList
                                datatype='lesion'
                                // dataid={lesion?.id as string}
                                viewset={lesionlabelviewset as GIOrganLabelViewset}
                                // start={lesion?.dataset?.start_ts as number}
                                // stop={lesion?.dataset?.stop_ts as number}
                                showLabel={true}
                                // multiSelection={multiSelection}
                                // onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />


                        ) : (

                            <Heatmap
                                datatype='organ'
                                // dataid={lesion?.id as string}
                                viewset={lesionlabelviewset as LesionLabelViewset}
                                width={500}
                                height={480}
                                rows={rows}
                                cols={cols}
                                margin={
                                    {
                                        top: 10,
                                        right: 5,
                                        bottom: 20,
                                        left: 5
                                    }
                                }
                                onViewsetUpdated={handleViewsetChanged}
                                onItemSelected={handleFrameSelected}
                            />

                        )}
                    </Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={9} alignItems='flex-start'>
                                <Stack direction='row' spacing={1} alignItems='left' justifyContent='left'>

                                    <FormControlLabel
                                        label={multiSelection ? lesionlabelviewset?.selitems?.length + ' selected' : 'Enable Multi-Select'}
                                        control={<Checkbox checked={multiSelection} onChange={toggleMultiSelection} />}
                                    />
                                    <div>
                                        <Button
                                            id="demo-customized-button"
                                            aria-controls={open ? 'demo-customized-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            variant="contained"
                                            disableElevation
                                            onClick={handleDDMenuClick}
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            Mark as
                                        </Button>
                                        <DropDownMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                            }}
                                            anchorEl={ddanchorEl}
                                            open={open}
                                            onClose={handleDDMenuClose}
                                        >
                                            <MenuItem onClick={handleDDMenuClose} disableRipple>
                                                Normal
                                            </MenuItem>
                                            <MenuItem onClick={handleDDMenuClose} disableRipple>
                                                Lesion
                                            </MenuItem>
                                        </DropDownMenu>
                                    </div>
                                    <ButtonGroup
                                        aria-label='tool-buttons'
                                        disabled={lesionlabelviewset?.selitem === null || lesionlabelviewset?.selitem === undefined}
                                    >
                                        <IconButton aria-label="delete"
                                            onClick={deleteSelectedItems}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton aria-label="add-in-report"
                                            onClick={markSelectionsForExport}
                                        >
                                            <Tooltip title="Add to report">
                                                <NoteAddIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton aria-label="remove-from-report"
                                            onClick={unmarkSelectionsForExport}
                                        >
                                            <Tooltip title="Remove from report">
                                                <ClearIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </ButtonGroup>
                                </Stack>
                            </Grid>
                            <Grid item xs={3} alignItems='flex-end'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    sx={{
                                        width: 120,
                                        mr: 2,
                                        pr: 0
                                    }}
                                    endIcon={loading ? <CircularProgress size={16} /> : <MoreHorizIcon />}
                                    onClick={handleLoadMore}
                                    disabled={loading}>
                                    {loading ? 'Loading' : 'Load more'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
                <Box
                    sx={{
                        width: '50%',
                        ml: 4
                    }}
                >
                    <Grid container direction='column' spacing={1} alignItems='center' justifyContent='center'>
                        <Grid item xs={12}>
                            <LesionLabelImageView
                                image={lesionlabelviewset?.selitem?.dataframe?.imagedata as string}
                                canvaswidth={500}
                                canvasheight={320} />
                            {/* <Stage width={canvaswidth} height={canvasheight}>
                                <Layer>
                                    <Image
                                        image={selFrameImage}
                                        x={imageStartPos[0]}
                                        y={imageStartPos[1]}
                                        scaleX={zoomLevel}
                                        scaleY={zoomLevel}
                                        ref={imageRef}
                                        draggable={true}
                                        onDragStart={dragStart}
                                        onDragEnd={dragEnd}
                                    />
                                </Layer>

                            </Stage>
                            <CustomFab
                                aria-label="zoomin"
                                onClick={() => { zoomIn() }}
                                disabled={zoomLevel < 2 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 96
                                }}>
                                <ZoomInIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="zoomout"
                                onClick={() => { zoomOut() }}
                                disabled={zoomLevel > 1 ? false : true}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 144
                                }}>
                                <ZoomOutIcon fontSize="large" />
                            </CustomFab>
                            <CustomFab
                                aria-label="reset"
                                onClick={resetImageView}
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: 192
                                }}>
                                <RestartAltIcon fontSize="large" />
                            </CustomFab> */}

                        </Grid>
                        <Grid item xs={12}>
                            <IconButton edge='end' aria-label='First frame'>
                                <SkipPreviousIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Previous frame' onClick={prevFrame}>
                                <NavigateBeforeIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Next frame' onClick={nextFrame}>
                                <NavigateNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            <IconButton edge='end' aria-label='Last frame'>
                                <SkipNextIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <Grid container spacing={1} alignItems='flex-start' justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <EditLesionLabel lesionlabel={lesionlabelviewset?.selitem as LesionLabel} onEdit={handleLesionLabelEdited} />

                        </Grid>

                    </Grid>

                </Box>
            </Stack>
        </>
    )
}