import * as d3 from 'd3';
import * as React from "react";
import { GIOrganLabel, GIOrganLabelViewset, ImageDataframe, LesionLabel, LesionLabelViewset } from '../../../types';
import { fetchGIOrganLabelAPI, fetchLesionLabelAPI } from '../../../reducers/func/dataManagementFunc';
import { set } from 'lodash';
import { Box } from '@mui/material';
import { el } from 'date-fns/locale';

interface IHeatmap {
    datatype: string
    // dataid: string
    viewset: GIOrganLabelViewset | LesionLabelViewset | null
    width: number
    height: number
    rows: number
    cols: number
    margin: {
        top: number
        right: number
        bottom: number
        left: number
    }
    onViewsetUpdated: (viewset: GIOrganLabelViewset | LesionLabelViewset) => void
    onItemSelected: (item: ImageDataframe) => void
}

export const Heatmap: React.FC<IHeatmap> = (props: IHeatmap) => {
    const { datatype, viewset, width, height, rows, cols, margin,
        onViewsetUpdated, onItemSelected
    } = props
    // const [data, setData] = React.useState<GIOrganLabel[] | LesionLabel[] | null>(null)
    const ref = React.useRef<HTMLDivElement>(null)
    // const [chart_width, setChartWidth] = React.useState<number>(width)
    // const [chart_height, setChartHeight] = React.useState<number>(height)
    // const [giorganlabelviewset, setGIOrganLabelViewset] = React.useState<GIOrganLabelViewset | null>(null)
    const mapColor = d3.scaleOrdinal(['unknown', 'esophagus', 'stomach', 'small bowel', 'colon'],
        ["#E0EFDE", "#DAB785", "#70A288", "#04395E", "#031D44"]);
    // const [X, setX] = React.useState<d3.ScaleBand<string>>()
    // const [Y, setY] = React.useState<d3.ScaleBand<string>>()
    const [rects, setRects] = React.useState<(JSX.Element | undefined)[]>()
    const block_width = Math.ceil((width - margin.left - margin.right) / cols)
    const block_height = block_width

    const prepareHeatmap = (data: GIOrganLabel[] | LesionLabel[] | null, page: number) => {
        if (data === null || data === undefined) {
            return
        }
        const new_chart_width = block_width * cols + margin.left + margin.right
        const new_chart_height = block_height * rows * page + margin.top + margin.bottom
        // setChartHeight(new_chart_height)
        // setChartWidth(new_chart_width)
        // Build X groups which is the number from 0 to 99
        var XGroups = Array.from(Array(cols).keys()).map((item) => item.toString());
        // Build Y groups which is the number from 0 to length of data/100
        var YGroups = Array.from(Array(rows * page).keys()).map((item) => item.toString());
        // Build X scales and axis:
        const x = d3
            .scaleBand()
            .range([0, block_width * cols])
            .domain(XGroups)
            .padding(0.1);
        // Build Y scales and axis:
        var y = d3
            .scaleBand()
            .range([0, block_height * rows * page])
            .domain(YGroups)
            .padding(0.1);
        // setX(x)
        // setY(y)

        // Build rectangle for each data element
        const rects = data.map((d: GIOrganLabel | LesionLabel, i: number) => {
            if (d.label === null || d.label === undefined) {
                return
            }
            return (
                <rect
                    key={i}
                    x={x((i % cols).toString())}
                    y={y((Math.floor(i / cols)).toString())}
                    width={x.bandwidth()}
                    height={y.bandwidth()}
                    fill={mapColor(d.labelname.split('-')[0].trimEnd() as string)}
                />
            )
        })
        setRects(rects)
        // remove the old svg
        d3.select(ref.current).selectAll("svg").remove()
        // append the svg object to the body of the page
        const svg = d3.select(ref.current)
            .append("svg")
            .attr("width", new_chart_width)
            .attr("height", new_chart_height)
            .append("g")
            .attr("width", new_chart_width)
            .attr("height", new_chart_height)
            .attr("transform", `translate(${margin.left}, ${margin.top})`)

        // Tooltip
        var tooltip = d3.select(ref.current)
            .append("div")
            .style("position", "absolute")
            .style("display", "inline-block")
            .style("background-color", "white")
            .style("border", "1px solid #ccc")
            .style("border-radius", "3px")
            .style("visibility", "hidden")
            .style("padding", "5px")
            .style("text-align", "center")
            .html("<span>a simple tooltip</span>");
        // Add rects
        svg.selectAll()
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d: GIOrganLabel | LesionLabel, i: number) => x((i % cols).toString()) as number)
            .attr("y", (d: GIOrganLabel | LesionLabel, i: number) => y((Math.floor(i / cols)).toString()) as number)
            .attr("width", x.bandwidth())
            .attr("height", y.bandwidth())
            .style("fill", (d: GIOrganLabel | LesionLabel) => mapColor(d.labelname.split('-')[0].trimEnd() as string))
            .style("stroke", (d: GIOrganLabel | LesionLabel) => {
                if (d.id === viewset?.selitem?.id) {
                    return "#000000"
                }
                return "none"
            })
            .style("stroke-width", 2)
            .on("mouseover", function (event, d) {
                tooltip.html(`<img src="` + d.dataframe?.imagedata + `" width="80px" style="display: block" /><p>` + d.labelname + `</p>`);
                return tooltip.style("visibility", "visible");
            })
            .on("mousemove", function (event, d) {
                return tooltip.style("top", (event.pageY - 90) + "px").style("left", (event.pageX - 180) + "px")
            })
            .on("mouseout", function (event, d) { return tooltip.style("visibility", "hidden"); })
            .on("mousedown", function (event, d) {
                return tooltip.style("visibility", "hidden");
            })
            .on("mouseup", (event, d) => {
                handleRectSelected(d, viewset);
            });

    }

    // const handleFetchData = (page: number) => {
    //     if (dataid) {
    //         if (datatype === 'organ') {
    //             fetchGIOrganLabelAPI(dataid, "", page, rows * cols)
    //                 .then((response) => {
    //                     if (page > 1 && viewset !== null) {
    //                         onViewsetUpdated({
    //                             ...viewset,
    //                             data: viewset?.data?.concat(response.data.results),
    //                             page: page,
    //                             count: response.data.count,
    //                         })
    //                         // prepareHeatmap(viewset?.data?.concat(response.data.results) as GIOrganLabel[], page)

    //                     } else {
    //                         onViewsetUpdated({
    //                             //...giorganlabelviewset,
    //                             data: response.data.results,
    //                             totalpages: response.data.total_pages,
    //                             page: 1,
    //                             count: response.data.count,
    //                             // selframe: response.data.results[0].dataframe,
    //                             selitem: response.data.results[0],
    //                             pointer: 0
    //                         })
    //                         // prepareHeatmap(response.data.results, 1)
    //                     }

    //                 })
    //                 .catch((error) => {
    //                     console.log("Error fetching organ labels: ", error)
    //                 })
    //         } else if (datatype === 'lesion') {
    //             fetchLesionLabelAPI(dataid, "", page, rows * cols)
    //                 .then((response) => {
    //                     if (page > 1 && viewset !== null) {
    //                         onViewsetUpdated({
    //                             ...viewset,
    //                             data: viewset?.data?.concat(response.data.results),
    //                             page: page,
    //                             count: response.data.count,
    //                         })
    //                         // prepareHeatmap(viewset?.data?.concat(response.data.results) as GIOrganLabel[], page)

    //                     } else {
    //                         onViewsetUpdated({
    //                             //...giorganlabelviewset,
    //                             data: response.data.results,
    //                             totalpages: response.data.total_pages,
    //                             page: 1,
    //                             count: response.data.count,
    //                             // selframe: response.data.results[0].dataframe,
    //                             selitem: response.data.results[0],
    //                             pointer: 0
    //                         })
    //                         // prepareHeatmap(response.data.results, 1)
    //                     }
    //                 })
    //                 .catch((error) => {
    //                     console.log("Error fetching lesion labels: ", error)
    //                 })
    //         }
    //     }
    // }

    // const handleHeatmapScrollEnd = (event: any) => {
    //     if (event.currentTarget.offsetHeight + Math.ceil(event.currentTarget.scrollTop) >= height) {
    //         handleFetchData(viewset?.page as number + 1)
    //     }
    // }

    const handleRectSelected = function (d: GIOrganLabel, vs: GIOrganLabelViewset | LesionLabelViewset | null) {
        if (vs === null) {
            return
        }
        if (d.dataframe) {
            onItemSelected(d.dataframe)
        }

    }

    React.useEffect(() => {

        // if (datatype === 'organ' && viewset === null) {
        //     handleFetchData(1)
        // }
        if (viewset !== null && viewset.data !== undefined && viewset.page !== undefined) {
            prepareHeatmap(viewset.data, viewset.page)
        }
    }, [viewset])


    return (
        <Box
            sx={{
                width: width,
                height: height - 60,
                mb: '10px',
                overflow: 'auto',
            }}
        // onScroll={handleHeatmapScrollEnd}
        >
            <div ref={ref}>
                {/*
                <svg
                    width={chart_width + 10}
                    height={chart_height}
                >
                    <g
                        width={chart_width}
                        height={chart_height}
                        transform={`translate(${margin.left}, ${margin.top})`}>
                        {rects}
                    </g>
                </svg>
                */}

            </div>
        </Box>
    )
}
